<template>
    <div>
        <div class="collect-top">
            <div class="ct-left">
                <div class="c-f">付款给崆峒山年卡办理</div>
                <div class="c-user-name">
                    {{info.collect_name}}
                </div>
            </div>
            <div class="ct-right">
                <img :src="info.headimgurl">
            </div>
        </div>
        <div class="wrap">
            <van-field label-width="70" readonly clickable v-model="value" label="付款金额" placeholder="请输入付款金额"
                :rules="[{ required: true}]"></van-field>
            <p class="msg">请注意付款金额</p>
        </div>
        <van-number-keyboard v-model="value" :show="true" theme="custom" extra-key="." close-button-text="付款"
            @close="submit">
        </van-number-keyboard>
    </div>
</template>

<script>
    import { collect_info, collect_submit } from '@/api/collect.js';
    import { wxPay } from '@/static/js/pay.js';
    import {
        Toast,
        Dialog
    } from 'vant';
    export default {
        name: 'collect',
        data() {
            return {
                info: {},
                id: '',
                value: '',
            }
        },
        mounted() {
            this.get_data();
        },
        methods: {
            get_data() {
                collect_info({
                    id: this.$route.query.id
                }).then(res => {
                    if (res.code == 1) {
                        this.info = res.data;
                        if (res.data.default_money) {
                            this.value = res.data.default_money;
                        } else {
                            this.value = 0;
                        }
                    }
                });
            },

            //提交数据
            submit() {
                var that = this;
                var money = this.value;
                if (money <= 0) {
                    Toast('付款金额不允许小于0元！');
                    return false;
                }
                collect_submit({
                    money: money,
                    uid: this.$route.query.id,
                    loading: true,
                    loading_text: '付款中……',
                }).then(res => {
                    if (res.code == 1) {
                        wxPay(res.data.info).then(res2 => {
                            Dialog({
                                message: '付款成功',
                            });
                        }).catch(err => {
                            Dialog({
                                message: '取消支付',
                            });
                        });
                    }
                });
            },

        }
    }
</script>

<style scoped>
    .wrap {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        position: relative;
        padding: 20px 20px 0;
        background: #fff;
        height: calc(100vh - 150px);

    }

    .layer-content {
        position: absolute;
        left: 50%;
        width: 100%;
        max-width: 640px;
        height: auto;
        z-index: 12;
        bottom: 0px;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
    }


    .collect-top {
        background: #ededed;
        width: 100%;
        height: 100px;
    }

    .collect-top .ct-left {
        padding-left: 15px;
        padding-top: 20px;
        height: 100px;
        width: 60%;
        float: left;
    }

    .collect-top .ct-right {
        text-align: right;
        margin-right: 15px;
        margin-top: 20px;
        height: 100px;
        width: 30%;
        float: right;
    }

    .collect-top .ct-right img {
        width: 50px;
        height: 50px;
        border-radius: 5px;
    }

    .collect-top .c-f {
        font-weight: 900;
        font-size: 14px;
    }

    .collect-top .c-user-name {
        color: #888888;
        font-size: 12px;

    }

    .msg {
        color: #aaa;
        font-size: 14px;
        margin-left: 15px;
        margin-top: 5px;
    }

    .van-cell--clickable {
        border-bottom: 1px solid #ccc;
    }

    /deep/ .van-field__control {
        font-size: 30px;
        font-weight: 700;
        color: red;
    }

    .van-number-keyboard {
        padding-bottom: 0;
    }

    /deep/ .van-key--blue {
        background-color: var(--main-color);
    }
</style>